import React from "react";
//models
import {TravelUrbanRel} from "../../../../../api/travel_urban/travelUrbanModel";
//libs
import config from "../../../../../config";
import {faCar} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
//types
import {Coordinates} from "../types";

//interface
interface Props extends Coordinates {
    travel_urban?: TravelUrbanRel;
}

//Component
const TravelUrbanSharingMarkerDriver = (props: Props) => {

    return (
        <div
            style={{
                backgroundColor: "#fff",
                borderColor: config.colorPrimary,
                borderStyle: "solid",
                borderWidth: 4,
                borderRadius: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 20,
                width: 40,
                height: 40,
                boxShadow: "0px 2px 6px rgba(0,0,0,0.4)"
            }}
        >
            <FontAwesomeIcon
                icon={faCar}
                color={config.colorPrimary}
            />
        </div>
    )
}

export default TravelUrbanSharingMarkerDriver;
