import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import './style.scss';
import App from './App';
import {AppRegistry} from "react-native";
import configureStore from "./store/store";

let store = configureStore();

const AppGlobal = () => {
    return (
        <Provider
            store={store}
        >
            <App/>
        </Provider>
    )
}

ReactDOM.render(<AppGlobal/>, document.getElementById("root"))
AppRegistry.registerComponent('App', () => AppGlobal);
AppRegistry.runApplication('App', {rootTag: document.getElementById('root')});
