import React from "react";
//theme
import {Route, Switch} from "react-router-dom";
//libs
//views
import TravelUrbanSharing from "./app/travel_urban/sharing";
import TravelInterurbanUserSharing from "./app/travel_interurban_user/sharing";
//const
//component
const PassengerSwitch = () => {
    return (
        <Switch>
            <Route
                path="/app/passenger/sharing/urban/:code?/:type?"
                component={TravelUrbanSharing}
                exact
            />
            <Route
                path="/app/passenger/sharing/interurban/:code?/:type?"
                component={TravelInterurbanUserSharing}
                exact
            />
        </Switch>
    )
}

export default PassengerSwitch;
