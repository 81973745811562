/*global google*/
import React, {useEffect, useRef, useState} from 'react';
//theme
import GoogleMapReact from 'google-map-react';
//api
import {TravelInterurbanUserAPI} from '../../../../../api/travel_interurban_user/travelInterurbanUserAPI';
//models
import {TravelInterurbanUser} from '../../../../../api/travel_interurban_user/travelInterurbanUserModel';
//libs
import config from '../../../../../config';
import {useParams} from "react-router-dom";
//views
import SharingTravelPassengerType1 from "../_components/TravelInterurbanUserSharingPanelPassengerType1";
import TravelInterurbanUserSharingPanelPassengerType2
    from "../_components/TravelInterurbanUserSharingPanelPassengerType2";
import TravelInterurbanUserSharingMarkerPassenger from "../_components/TravelInterurbanUserSharingMarkerPassenger";
import TravelInterurbanUserSharingMarkerPointA from "../_components/TravelInterurbanUserSharingMarkerPointA";
import TravelInterurbanUserSharingMarkerPointB from "../_components/TravelInterurbanUserSharingMarkerPointB";
import TravelInterurbanUserSharingMarkerDriver from "../_components/TravelInterurbanUserSharingMarkerDriver";
import TravelInterurbanUserSharingPanelDriverType1 from "../_components/TravelInterurbanUserSharingPanelDriverType1";
import TravelInterurbanUserSharingPanelDriverType2 from "../_components/TravelInterurbanUserSharingPanelDriverType2";
//types
import {Coordinates, TravelInterurbanUserStackParamsList} from "../types";
//const
const logo = require("../../../../../assets/img/logo.png").default;
const TAG = "TravelInterurbanUserSharing";
const isDev = (process.env.NODE_ENV === 'production') ? false : true;
//Views
const TravelInterurbanUserSharing = () => {
    //hooks
    const params = useParams<TravelInterurbanUserStackParamsList["TravelInterurbanUserSharing"]>();
    //refs
    const iSync = useRef<NodeJS.Timeout | number>(0);
    //state
    const [travel_interurban_user, setTravelInterurbanUser] = useState<TravelInterurbanUser>({});
    const [type1, setType1] = useState<boolean>(false);
    const [type2, setType2] = useState<boolean>(false);
    const [origin, setOrigin] = useState<Coordinates>({
        lat: 0.00, lng: 0.00
    });
    const [destiny, setDestiny] = useState<Coordinates>({
        lat: 0.00, lng: 0.00
    });
    const [isTravelNull, setIsTravelNull] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isMapToOrigin, setIsMapToOrigin] = useState<boolean>(false);
    const [isMapToDestiny, setIsMapToDestiny] = useState<boolean>(false);
    //effects
    useEffect(() => {
        documentApp();
        getCurrentPosition();
        //obteniendo params de la view
        const {code, type} = params;
        verifiedTravel(String(code), String(type));

        iSync.current = setInterval(async () => {
            await verifiedTravel(String(code), String(type));
        }, 3000);
        return () => {
            clearInterval(iSync.current as NodeJS.Timeout);
            iSync.current = 0;
        }
    }, []);

    const documentApp = () => {
        document.title = `Observa como viaja con ${config.appName} | Powered By ${config.provider}`;
    }

    const getCurrentPosition = async () => {
        return new Promise(async (resolve, reject) => {
            if (navigator && navigator.geolocation) {
                await navigator.geolocation.getCurrentPosition(pos => {
                    const coords = pos.coords;
                    setOrigin({
                        lat: coords.latitude,
                        lng: coords.longitude
                    });
                    setDestiny({
                        lat: coords.latitude,
                        lng: coords.longitude
                    });
                    setIsLoading(false);
                    resolve(coords);
                });
            } else {
                reject(false);
            }
        });
    };

    const getTravelInterurbanUserByCode = async (code: string): Promise<TravelInterurbanUser> => {
        return await new Promise<TravelInterurbanUser>(async (resolve, reject) => {
            await TravelInterurbanUserAPI.getByCode(code).then(async res => {
                isDev && console.log(TAG + "//getTravelInterurbanUserByCode", res.code, res.message, res.error);
                if (res.code === 200) {
                    setTravelInterurbanUser(res.travel_interurban_user);
                    setIsLoading(false);
                    return resolve(res.travel_interurban_user);
                } else {
                    setIsTravelNull(true);
                    clearInterval(iSync.current as NodeJS.Timeout);
                    iSync.current = 0;
                    setIsLoading(false);
                    return reject(res);
                }
            }).catch(async res => {
                isDev && console.error(TAG + "//getTravelInterurbanUserByCode", res.code, res.error, res);
                setIsLoading(false);
                return reject(res);
            });
        });
    }

    const verifiedTravel = async (code: string, type: string) => {
        if (type === "1") {
            setType1(true);
            setType2(false);
        } else if (type === "2") {
            setType1(false);
            setType2(true);
        } else {
            setType1(true);
            setType2(false);
        }
        await getTravelInterurbanUserByCode(code).then(async travel_interurban_user => {
            if (travel_interurban_user) {
                if (
                    travel_interurban_user.travel_interurban_user_status_id === 1
                    || travel_interurban_user.travel_interurban_user_status_id === 2
                ) {
                    setOrigin({
                        lat: travel_interurban_user?.travel_interurban_schedule?.user_driver?.lat,
                        lng: travel_interurban_user?.travel_interurban_schedule?.user_driver?.lng,
                    });
                    setDestiny({
                        lat: Number(travel_interurban_user.ori_lat),
                        lng: Number(travel_interurban_user.ori_lng),
                    });
                    setIsMapToOrigin(true);
                    setIsMapToDestiny(false);
                } else if (
                    travel_interurban_user.travel_interurban_user_status_id === 3
                    || travel_interurban_user.travel_interurban_user_status_id === 4
                    || travel_interurban_user.travel_interurban_user_status_id === 5
                ) {
                    setOrigin({
                        lat: travel_interurban_user?.travel_interurban_schedule?.user_driver?.lat,
                        lng: travel_interurban_user?.travel_interurban_schedule?.user_driver?.lng,
                    });
                    setDestiny({
                        lat: Number(travel_interurban_user.desti_lat),
                        lng: Number(travel_interurban_user.desti_lng),
                    });
                    setIsMapToOrigin(false);
                    setIsMapToDestiny(true);
                }

            }
        });
    }

    return (
        <div
            style={{width: '100vw', height: '100vh', backgroundColor: '#fff'}}
        >

            {type1 && isMapToOrigin ? <SharingTravelPassengerType1
                travel_interurban_user={travel_interurban_user}
            /> : null}

            {type1 && isMapToDestiny ? <TravelInterurbanUserSharingPanelPassengerType2
                travel_interurban_user={travel_interurban_user}
            /> : null}

            {type2 && isMapToOrigin ? <TravelInterurbanUserSharingPanelDriverType1
                travel_interurban_user={travel_interurban_user}
            /> : null}

            {type2 && isMapToDestiny ? <TravelInterurbanUserSharingPanelDriverType2
                travel_interurban_user={travel_interurban_user}
            /> : null}


            <div
                style={{
                    width: '100%',
                    height: "100vh",
                }}
            >
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: config.googleAPIKey
                    }}
                    center={{
                        lat: origin.lat,
                        lng: origin.lng
                    }}
                    defaultZoom={15}
                    options={{
                        disableDefaultUI: true
                    }}

                >
                    {travel_interurban_user && isMapToOrigin && <TravelInterurbanUserSharingMarkerPassenger
                        lat={Number(travel_interurban_user.ori_lat)}
                        lng={Number(travel_interurban_user.ori_lng)}
                    />}

                    {travel_interurban_user && travel_interurban_user?.travel_interurban_schedule?.user_driver &&
                    <TravelInterurbanUserSharingMarkerDriver
                        lat={travel_interurban_user?.travel_interurban_schedule?.user_driver?.lat}
                        lng={travel_interurban_user?.travel_interurban_schedule?.user_driver?.lng}
                    />}

                    {travel_interurban_user && isMapToOrigin && <TravelInterurbanUserSharingMarkerPointA
                        lat={Number(travel_interurban_user.ori_lat)}
                        lng={Number(travel_interurban_user.ori_lng)}
                    />}

                    {travel_interurban_user && isMapToDestiny && <TravelInterurbanUserSharingMarkerPointB
                        lat={Number(travel_interurban_user.desti_lat)}
                        lng={Number(travel_interurban_user.desti_lng)}
                    />}

                </GoogleMapReact>
            </div>


            {isLoading ? <div style={{
                backgroundColor: 'rgba(0,0,0,0.5)',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <img style={{width: '10rem'}} src={logo} alt=""/>
            </div> : null}

            {isTravelNull ? <div style={{
                backgroundColor: 'rgba(0,0,0,0.6)',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <div style={{
                    backgroundColor: '#eee',
                    padding: 20,
                    paddingTop: 40,
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    borderRadius: 20
                }}>
                    <img style={{width: '10rem', marginBottom: 15}} src={logo} alt=""/>
                    <h3>No Disponible</h3>
                    <h6>El viaje ya no se encuentra disponible</h6>
                </div>

            </div> : null}

            {/*logo de la empresa*/}
            <img style={{
                position: "absolute", zIndex: 1000, bottom: 20, right: 20, width: '3.5rem', opacity: .9
            }} src={logo} alt=""/>
        </div>
    );
}

export default TravelInterurbanUserSharing;
