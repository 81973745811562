//libs
import config from '../../config';
import ResolverFetch from "../../libs/_resolver_fetch";
import {getToken} from "../system/_token";
//model
import {TravelUrbanResponse} from "./travelUrbanModel";
//api
export const TravelUrbanAPI = {
    getByCode: async (code: string) => {
        return await new Promise<TravelUrbanResponse>(async (resolve: (value: TravelUrbanResponse) => void, reject: (reason: TravelUrbanResponse) => void) => {
            await ResolverFetch(`${config.host}/api/travel/urban?type=getByCode&code=${code}`, {
                "method": "GET",
                "headers": {
                    "Accept": "application/json",
                    "authorization": await getToken()
                },
            }).then(async res => {
                return resolve({
                    code: res.code,
                    message: res.message,
                    error: res.error,
                    travel_urban: (res.is_resolve && res.data) && res.data.travel_urban,
                });
            }).catch(async res => {
                return reject({
                    ...res,
                    code: res.code,
                    error: res.error,
                });
            });
        });
    },
}
