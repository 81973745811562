/*global google*/
import React, {useEffect, useRef, useState} from 'react';
//theme
import GoogleMapReact from 'google-map-react';
//api
import {TravelUrbanAPI} from '../../../../../api/travel_urban/travelUrbanAPI';
//models
import {TravelUrbanRel} from '../../../../../api/travel_urban/travelUrbanModel';
//libs
import config from '../../../../../config';
import {useParams} from "react-router-dom";
//views
import SharingTravelPassengerType1 from "../_components/TravelUrbanSharingPanelPassengerType1";
import TravelUrbanSharingPanelPassengerType2 from "../_components/TravelUrbanSharingPanelPassengerType2";
import TravelUrbanSharingMarkerPassenger from "../_components/TravelUrbanSharingMarkerPassenger";
import TravelUrbanSharingMarkerPointA from "../_components/TravelUrbanSharingMarkerPointA";
import TravelUrbanSharingMarkerPointB from "../_components/TravelUrbanSharingMarkerPointB";
import TravelUrbanSharingMarkerDriver from "../_components/TravelUrbanSharingMarkerDriver";
import TravelUrbanSharingPanelDriverType1 from "../_components/TravelUrbanSharingPanelDriverType1";
import TravelUrbanSharingPanelDriverType2 from "../_components/TravelUrbanSharingPanelDriverType2";
//types
import {Coordinates, TravelUrbanStackParamsList} from "../types";
//const
const logo = require("../../../../../assets/img/logo.png").default;
const TAG = "TravelUrbanSharing";
const isDev = (process.env.NODE_ENV === 'production') ? false : true;
//Views
const TravelUrbanSharing = () => {
    //hooks
    const params = useParams<TravelUrbanStackParamsList["TravelUrbanSharing"]>();
    //refs
    const iSync = useRef<NodeJS.Timeout | number>(0);
    //state
    const [travel_urban, setTravelUrban] = useState<TravelUrbanRel>({});
    const [type1, setType1] = useState<boolean>(false);
    const [type2, setType2] = useState<boolean>(false);
    const [origin, setOrigin] = useState<Coordinates>({
        lat: 0.00, lng: 0.00
    });
    const [destiny, setDestiny] = useState<Coordinates>({
        lat: 0.00, lng: 0.00
    });
    const [isTravelNull, setIsTravelNull] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isMapToOrigin, setIsMapToOrigin] = useState<boolean>(false);
    const [isMapToDestiny, setIsMapToDestiny] = useState<boolean>(false);
    //effects
    useEffect(() => {
        documentApp();
        getCurrentPosition();
        //obteniendo params de la view
        const {code, type} = params;
        verifiedTravel(String(code), String(type));

        iSync.current = setInterval(async () => {
            await verifiedTravel(String(code), String(type));
        }, 3000);
        return () => {
            clearInterval(iSync.current as NodeJS.Timeout);
            iSync.current = 0;
        }
    }, []);

    const documentApp = () => {
        document.title = `Observa como viaja con ${config.appName} | Powered By ${config.provider}`;
    }

    const getCurrentPosition = async () => {
        return new Promise(async (resolve, reject) => {
            if (navigator && navigator.geolocation) {
                await navigator.geolocation.getCurrentPosition(pos => {
                    const coords = pos.coords;
                    setOrigin({
                        lat: coords.latitude,
                        lng: coords.longitude
                    });
                    setDestiny({
                        lat: coords.latitude,
                        lng: coords.longitude
                    });
                    setIsLoading(false);
                    resolve(coords);
                });
            } else {
                reject(false);
            }
        });
    };

    const getTravelUrbanByCode = async (code: string): Promise<TravelUrbanRel> => {
        return await new Promise<TravelUrbanRel>(async (resolve, reject) => {
            await TravelUrbanAPI.getByCode(code).then(async res => {
                isDev && console.log(TAG + "//getTravelUrbanByCode", res.code, res.message, res.error);
                if (res.code === 200) {
                    setTravelUrban(res.travel_urban);
                    setIsLoading(false);
                    return resolve(res.travel_urban);
                } else {
                    setIsTravelNull(true);
                    clearInterval(iSync.current as NodeJS.Timeout);
                    iSync.current = 0;
                    setIsLoading(false);
                    return reject(res);
                }
            }).catch(async res => {
                isDev && console.error(TAG + "//getTravelUrbanByCode", res.code, res.error, res);
                setIsLoading(false);
                return reject(res);
            });
        });
    }

    const verifiedTravel = async (code: string, type: string) => {
        if (type === "1") {
            setType1(true);
            setType2(false);
        } else if (type === "2") {
            setType1(false);
            setType2(true);
        } else {
            setType1(true);
            setType2(false);
        }
        await getTravelUrbanByCode(code).then(async travel_urban => {
            if (travel_urban) {
                if (travel_urban.travel_urban_status_id === 2) {
                    setOrigin({
                        lat: travel_urban.user_driver?.lat,
                        lng: travel_urban.user_driver?.lng,
                    });
                    setDestiny({
                        lat: travel_urban.ori_lat,
                        lng: travel_urban.ori_lng,
                    });
                    setIsMapToOrigin(true);
                    setIsMapToDestiny(false);
                } else if (travel_urban.travel_urban_status_id === 3) {
                    setOrigin({
                        lat: travel_urban.user_driver?.lat,
                        lng: travel_urban.user_driver?.lng,
                    });
                    setDestiny({
                        lat: travel_urban.desti_lat,
                        lng: travel_urban.desti_lng,
                    });
                    setIsMapToOrigin(false);
                    setIsMapToDestiny(true);
                }

            }
        });
    }

    return (
        <div
            style={{width: '100vw', height: '100vh', backgroundColor: '#fff'}}
        >

            {type1 && isMapToOrigin ? <SharingTravelPassengerType1
                travel_urban={travel_urban}
            /> : null}

            {type1 && isMapToDestiny ? <TravelUrbanSharingPanelPassengerType2
                travel_urban={travel_urban}
            /> : null}

            {type2 && isMapToOrigin ? <TravelUrbanSharingPanelDriverType1
                travel_urban={travel_urban}
            /> : null}

            {type2 && isMapToDestiny ? <TravelUrbanSharingPanelDriverType2
                travel_urban={travel_urban}
            /> : null}


            <div
                style={{
                    width: '100%',
                    height: "100vh",
                }}
            >
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: config.googleAPIKey
                    }}
                    center={{
                        lat: origin.lat,
                        lng: origin.lng
                    }}
                    defaultZoom={15}
                    options={{
                        disableDefaultUI: true
                    }}

                >
                    {travel_urban && isMapToOrigin && <TravelUrbanSharingMarkerPassenger
                        lat={travel_urban.ori_lat}
                        lng={travel_urban.ori_lng}
                    />}

                    {travel_urban && travel_urban.user_driver && <TravelUrbanSharingMarkerDriver
                        lat={travel_urban.user_driver?.lat}
                        lng={travel_urban.user_driver?.lng}
                    />}

                    {travel_urban && isMapToOrigin && <TravelUrbanSharingMarkerPointA
                        lat={travel_urban.ori_lat}
                        lng={travel_urban.ori_lng}
                    />}

                    {travel_urban && isMapToDestiny && <TravelUrbanSharingMarkerPointB
                        lat={travel_urban.desti_lat}
                        lng={travel_urban.desti_lng}
                    />}

                </GoogleMapReact>
            </div>


            {isLoading ? <div style={{
                backgroundColor: 'rgba(0,0,0,0.5)',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <img style={{width: '10rem'}} src={logo} alt=""/>
            </div> : null}

            {isTravelNull ? <div style={{
                backgroundColor: 'rgba(0,0,0,0.6)',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <div style={{
                    backgroundColor: '#eee',
                    padding: 20,
                    paddingTop: 40,
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    borderRadius: 20
                }}>
                    <img style={{width: '10rem', marginBottom: 15}} src={logo} alt=""/>
                    <h3>No Disponible</h3>
                    <h6>El viaje ya no se encuentra disponible</h6>
                </div>

            </div> : null}

            {/*logo de la empresa*/}
            <img style={{
                position: "absolute", zIndex: 1000, bottom: 20, right: 20, width: '3.5rem', opacity: .9
            }} src={logo} alt=""/>
        </div>
    );
}

export default TravelUrbanSharing;
