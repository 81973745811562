const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://colex.dvgeo.app' : 'http://192.168.100.106:3062',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://colex.dvgeo.app' : 'http://192.168.100.106:3062',
    api: 'api/',
    apiSocket: 'colex::1.2',
    nameDir: 'colex',
    package: 'app.dvgeo.colex.web',
    version: '1.0.0',
    googleAPIKey: 'AIzaSyA0Awg36NeOjCngW2sBVrQ2Fap2SZW1Tog',
    appName: 'Colex',
    provider: 'Devjos CIA LTDA',
    colorPrimary: '#010100',
    colorDark: '#2BD3AF',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'app.dvgeo.colex.passenger',
    playStoreDriverId: 'app.dvgeo.colex.driver',
    appStorePassengerId: '6471604020',
    appStoreDriverId: '6471604248',
    email: "colexvzla@gmail.com",
};
export default config;
